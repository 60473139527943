<template>
    <div class="h-full">
        <div v-show="!config.isDetailDisplay"  :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
            <Control ref="control" :config="config" @onChangeEditDataSource="onChangeEditDataSource" @onChangeDetailDataSource="onChangeDetailDataSource"></Control>
        </div>
        <div v-show="config.isDetailDisplay" class="detail">
            <EditControl ref="editApiContrl" :dataSource="editDataSource" :config="config"></EditControl>
        </div>
    </div>
</template>
<script>
export default {
    data(){
            return {
                config: {
                    isDetailDisplay:false,
                    dataSourceKey:null,
                    isAdd: false,
                    isCanEdit:false,
                },
                editDataSource: {
                },
                initialDataSource: {}
            }
        },
        methods:{
            onChangeEditDataSource: function (dataSourceKey) {
                this.config.dataSourceKey = dataSourceKey;
                this.config.isAdd=true;
                this.config.isCanEdit=true;
                if(!dataSourceKey || dataSourceKey<=0){
                    this.$refs.editApiContrl.addApi();
                }
            },
            onChangeDetailDataSource: function (dataSourceKey) {
                this.config.dataSourceKey = dataSourceKey;
                this.config.isAdd=false;
                this.config.isCanEdit=true;
            },
        },
        components:{
            "EditControl": resolve => { require(['./components/clienteditapi.vue'], resolve) },
            "Control": resolve => { require(['./components/clientapilist.vue'], resolve) },
        }
}
</script>